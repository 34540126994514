import type { FC, SVGProps } from "react";

const ListsIcon: FC<SVGProps<SVGSVGElement>> = ({
    fill = "#000",
    width = "24",
    height = "24",
    ...props
}) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5 2h14a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Zm1.857 4.5a.857.857 0 0 0 0 1.714h10.286a.857.857 0 0 0 0-1.714H6.857ZM6 12c0 .473.384.857.857.857h10.286a.857.857 0 0 0 0-1.714H6.857A.857.857 0 0 0 6 12Zm0 4.357c0 .474.384.857.857.857h8.286a.857.857 0 0 0 0-1.714H6.857a.857.857 0 0 0-.857.857Z"
            fill={fill}
            fillRule="nonzero"
        />
    </svg>
);

export { ListsIcon };
