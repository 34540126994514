import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface ISideMenuStore {
    isCollapsed: boolean;
    setIsCollapsed: (isCollapsed: boolean) => void;
}

const useSideMenuStore = create<ISideMenuStore>()(
    devtools(
        persist(
            (set) => ({
                isCollapsed: false,
                setIsCollapsed: (isCollapsed: boolean) => set({ isCollapsed }),
            }),
            {
                name: "side-menu",
            }
        )
    )
);

export { useSideMenuStore };
