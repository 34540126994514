import Keycloak from "keycloak-js";
import {
    KEYCLOAK_CLIENT_ID,
    KEYCLOAK_REALM,
    KEYCLOAK_URL,
} from "../constants/constants";
import { captureException } from "@sentry/react";

const keycloak = new Keycloak({
    url: KEYCLOAK_URL,
    clientId: KEYCLOAK_CLIENT_ID,
    realm: KEYCLOAK_REALM,
});

let tokenPromise: any = null;

const getAccessToken: () => Promise<string | undefined> = async () => {
    return new Promise((resolve, reject) => {
        keycloak
            .updateToken(10)
            .then(() => {
                resolve(keycloak.token);
            })
            .catch((error: any) => {
                reject(error);
                captureException(error);
                keycloak.logout({ redirectUri: window.location.origin });
            });
    });
};

const getToken: () => Promise<string | undefined> = async () => {
    if (tokenPromise) {
        return tokenPromise;
    }
    if (keycloak.isTokenExpired(10)) {
        tokenPromise = getAccessToken();
        const token = await tokenPromise;
        tokenPromise = null;
        return token;
    }

    return keycloak.token;
};

const checkPermissions = (group: string): boolean =>
    keycloak.hasRealmRole(group);

export { keycloak, getToken, checkPermissions };
