import { FC, SVGProps } from "react";

const CloseIcon: FC<SVGProps<SVGSVGElement>> = ({ fill, ...props }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.5509 4.27677C19.1818 3.90774 18.5835 3.90774 18.2145 4.27678L12 10.4913L5.78553 4.27678C5.4165 3.90774 4.81818 3.90774 4.44914 4.27677L4.27678 4.44914C3.90774 4.81817 3.90774 5.41649 4.27677 5.78553L10.4912 12L4.27678 18.2145C3.90774 18.5835 3.90774 19.1818 4.27678 19.5509L4.44914 19.7232C4.81818 20.0923 5.4165 20.0923 5.78553 19.7232L12 13.5088L18.2145 19.7232C18.5835 20.0923 19.1818 20.0923 19.5509 19.7232L19.7232 19.5509C20.0923 19.1818 20.0923 18.5835 19.7232 18.2145L13.5088 12L19.7232 5.78553C20.0923 5.41649 20.0923 4.81817 19.7232 4.44914L19.5509 4.27677Z"
            fill={fill || "#2A2354"}
        />
    </svg>
);

export { CloseIcon };
