import { FC, SVGProps } from "react";

const ChevronLeftSmall: FC<SVGProps<SVGSVGElement>> = ({ fill, ...props }) => (
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="Icon/fill/chevron-left-small">
            <rect width="26" height="26" rx="13" fill="#F1F3F9" />
            <path
                id="Icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.3391 8.11161C15.7055 8.50621 15.6826 9.12312 15.288 9.48953L11.5075 13.0001L15.288 16.5106C15.6826 16.877 15.7055 17.4939 15.3391 17.8885C14.9727 18.2831 14.3558 18.3059 13.9612 17.9395L9.41117 13.7145C9.2125 13.53 9.09961 13.2712 9.09961 13.0001C9.09961 12.7289 9.2125 12.4701 9.41117 12.2856L13.9612 8.06058C14.3558 7.69417 14.9727 7.71702 15.3391 8.11161Z"
                fill="#C0C0CB"
            />
        </g>
    </svg>
);

export { ChevronLeftSmall };
