import { FC, SVGProps } from "react";

const AppsIcon: FC<SVGProps<SVGSVGElement>> = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.70156 3.90156C5.70156 4.89567 4.89568 5.70156 3.90156 5.70156C2.90745 5.70156 2.10156 4.89567 2.10156 3.90156C2.10156 2.90745 2.90745 2.10156 3.90156 2.10156C4.89568 2.10156 5.70156 2.90745 5.70156 3.90156ZM13.8016 3.90156C13.8016 4.89567 12.9957 5.70156 12.0016 5.70156C11.0075 5.70156 10.2016 4.89567 10.2016 3.90156C10.2016 2.90745 11.0075 2.10156 12.0016 2.10156C12.9957 2.10156 13.8016 2.90745 13.8016 3.90156ZM20.1016 5.70156C21.0957 5.70156 21.9016 4.89567 21.9016 3.90156C21.9016 2.90745 21.0957 2.10156 20.1016 2.10156C19.1075 2.10156 18.3016 2.90745 18.3016 3.90156C18.3016 4.89567 19.1075 5.70156 20.1016 5.70156ZM5.70156 12.0016C5.70156 12.9957 4.89568 13.8016 3.90156 13.8016C2.90745 13.8016 2.10156 12.9957 2.10156 12.0016C2.10156 11.0074 2.90745 10.2016 3.90156 10.2016C4.89568 10.2016 5.70156 11.0074 5.70156 12.0016ZM12.0016 13.8016C12.9957 13.8016 13.8016 12.9957 13.8016 12.0016C13.8016 11.0074 12.9957 10.2016 12.0016 10.2016C11.0075 10.2016 10.2016 11.0074 10.2016 12.0016C10.2016 12.9957 11.0075 13.8016 12.0016 13.8016ZM21.9016 12.0016C21.9016 12.9957 21.0957 13.8016 20.1016 13.8016C19.1075 13.8016 18.3016 12.9957 18.3016 12.0016C18.3016 11.0074 19.1075 10.2016 20.1016 10.2016C21.0957 10.2016 21.9016 11.0074 21.9016 12.0016ZM3.90156 21.9016C4.89568 21.9016 5.70156 21.0957 5.70156 20.1016C5.70156 19.1074 4.89568 18.3016 3.90156 18.3016C2.90745 18.3016 2.10156 19.1074 2.10156 20.1016C2.10156 21.0957 2.90745 21.9016 3.90156 21.9016ZM13.8016 20.1016C13.8016 21.0957 12.9957 21.9016 12.0016 21.9016C11.0075 21.9016 10.2016 21.0957 10.2016 20.1016C10.2016 19.1074 11.0075 18.3016 12.0016 18.3016C12.9957 18.3016 13.8016 19.1074 13.8016 20.1016ZM20.1016 21.9016C21.0957 21.9016 21.9016 21.0957 21.9016 20.1016C21.9016 19.1074 21.0957 18.3016 20.1016 18.3016C19.1075 18.3016 18.3016 19.1074 18.3016 20.1016C18.3016 21.0957 19.1075 21.9016 20.1016 21.9016Z"
            fill="#7370FF"
        />
    </svg>
);

export { AppsIcon };
