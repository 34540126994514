import React from "react";

const ShieldIcon = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M3 8.00367C3 7.17706 3 6.35159 3 5.52728C3 5.02567 3.26847 4.75442 3.76846 4.74751C6.13562 4.71526 8.31976 4.03915 10.3584 2.86545C10.7325 2.65007 11.109 2.43064 11.4617 2.18704C11.8312 1.93364 12.1661 1.9417 12.5379 2.18704C13.7504 2.9933 15.0413 3.66308 16.4333 4.11689C17.4998 4.47351 18.6123 4.67446 19.7364 4.71353C19.894 4.71756 20.0516 4.74002 20.2098 4.74348C20.7364 4.75557 20.9968 5.01876 20.9962 5.54974C20.9962 6.93191 21.0095 8.30832 20.9864 9.68704C20.9644 10.9713 20.9252 12.2567 20.8507 13.5387C20.8 14.2617 20.7106 14.9814 20.5828 15.6949C20.4096 16.7413 19.8461 17.6023 19.1764 18.3964C17.9639 19.8322 16.3952 20.7237 14.6447 21.3192C13.9103 21.5685 13.1493 21.7407 12.4011 21.9527C12.0922 22.039 11.792 21.9935 11.4848 21.925C9.83763 21.5582 8.25163 21.0347 6.81575 20.1236C5.52593 19.3046 4.50055 18.2415 3.80022 16.8749C3.46188 16.2149 3.32736 15.5002 3.26905 14.7786C3.16666 13.5059 3.09314 12.2312 3.0485 10.9546C3.01385 9.97269 3.04157 8.98789 3.04157 8.00425L3 8.00367Z"
            fill="#000"
        />
        <g clipPath="url(#clip0_74_465)">
            <path
                d="M11.0104 8.01991C11.0143 7.63092 11.1842 7.30001 11.5649 7.11226C11.9372 6.92891 12.3038 6.96963 12.6272 7.23648C12.8927 7.45457 13.0079 7.74761 12.9839 8.09485C12.9464 8.65449 12.9081 9.21412 12.869 9.77375C12.8218 10.4454 12.7741 11.1169 12.7258 11.7882C12.7172 11.9085 12.7143 12.0302 12.6968 12.1492C12.6714 12.3239 12.5837 12.4829 12.4504 12.5959C12.3172 12.7089 12.1477 12.768 11.9743 12.7619C11.8009 12.7558 11.6358 12.6848 11.5106 12.5627C11.3853 12.4405 11.3087 12.2757 11.2953 12.0997C11.2563 11.5963 11.2245 11.0924 11.1891 10.5888C11.1507 10.0422 11.1119 9.49549 11.0729 8.94882C11.0525 8.65189 11.0331 8.3547 11.0104 8.01991Z"
                color="white"
            />
            <path
                d="M13.001 14.9752C13.0016 15.1761 12.9437 15.3727 12.8346 15.5401C12.7255 15.7076 12.5702 15.8385 12.3882 15.9162C12.2061 15.994 12.0056 16.0152 11.8117 15.9771C11.6179 15.9391 11.4394 15.8434 11.2989 15.7023C11.1584 15.5612 11.062 15.3809 11.022 15.1842C10.982 14.9874 11.0001 14.783 11.0741 14.5967C11.148 14.4105 11.2745 14.2506 11.4376 14.1374C11.6007 14.0241 11.793 13.9626 11.9904 13.9604C12.2567 13.9586 12.5127 14.0645 12.7022 14.2548C12.8917 14.4451 12.9992 14.7042 13.001 14.9752Z"
                color="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_74_465">
                <rect
                    width="2"
                    height="9"
                    fill="white"
                    transform="translate(11 7)"
                />
            </clipPath>
        </defs>
    </svg>
);

export default ShieldIcon;
