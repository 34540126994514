import  { useContext } from "react";
import { ToastMessage } from "./toast-message";
import { ToastContext } from "./toast-provider";
import styles from "./toast.module.scss";

const ToastContainer = () => {
    const context = useContext<any>(ToastContext);

    function handleRemove(id: any) {
        context?.remove(id);
    }

    return (
        <div className={`${styles["toast-container"]}`}>
            <div>
                {context?.data.map((toast: any) => {
                    return (
                        <ToastMessage
                            key={toast.id}
                            id={toast.id}
                            message={toast.message}
                            type={toast.type}
                            header={toast.header}
                            icon={toast.icon}
                            onRemove={handleRemove}
                            lifetime={toast.lifetime}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export { ToastContainer };
