import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { IUser } from "../interfaces/user";

interface IAuthStore {
    isAuthentecated: boolean;
    isGlobalLoader: boolean;
    user: IUser | null;
    setIsAuthentecated: (isAuthentecated: boolean) => void;
    setUser: (user: IUser | null) => void;
    setIsGlobalLoader: (isGlobalLoader: boolean) => void;
}

const useAuthStore = create<IAuthStore>()(
    devtools(
        (set) => ({
            isAuthentecated: false,
            isGlobalLoader: false,
            user: null,
            setIsAuthentecated: (isAuthentecated: boolean) => {
                set((state) => {
                    return {
                        ...state,
                        isAuthentecated,
                    };
                });
            },
            setUser: (user: IUser | null) => {
                set((state) => {
                    return {
                        ...state,
                        user,
                    };
                });
            },
            setIsGlobalLoader: (isGlobalLoader: boolean) => {
                set((state) => {
                    return {
                        ...state,
                        isGlobalLoader,
                    };
                });
            },
        }),
        {
            name: "auth",
        }
    )
);

export { useAuthStore };
