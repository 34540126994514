import {
    useCallback,
    useContext,
    useState,
    useEffect,
    useRef,
    createContext,
} from "react";
import { ToastContainer } from "./toast-container";

export const ToastContext = createContext({});

const DEFAULT_INTERVAL = 5000;

const ToastProvider = ({ children }: any) => {
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const [data, setData] = useState<any[]>([]);

    const Push = useCallback(
        (message: string, type: string, header: string, lifetime: number) => {
            const new_item = {
                id: uuidv4(),
                message: message,
                type: type,
                header: header,
                lifetime: lifetime ? lifetime : DEFAULT_INTERVAL,
            };
            if (isMounted.current) {
                setData((prevState) => [...prevState, new_item]);
            }
        },
        [setData, data]
    );

    const PushError = useCallback(
        (message: string, header: string, lifetime: number) =>
            Push(message, "Error", header, lifetime),
        [Push]
    );
    const PushWarning = useCallback(
        (message: string, header: string, lifetime: number) =>
            Push(message, "Warning", header, lifetime),
        [Push]
    );
    const PushSuccess = useCallback(
        (message: string, header: string, lifetime: number) =>
            Push(message, "Success", header, lifetime),
        [Push]
    );

    const ToastContexd = useCallback(() => {
        return {
            data: data,
            pushError: PushError,
            pushWarning: PushWarning,
            pushSuccess: PushSuccess,
            push: Push,

            async remove(id: any) {
                if (isMounted.current) {
                    setData((prevState) => prevState.filter((e) => e.id != id));
                }
            },
        };
    }, [data, setData, PushError, PushWarning, PushSuccess, Push]);

    return (
        <ToastContext.Provider value={ToastContexd()}>
            <ToastContainer />
            {children}
        </ToastContext.Provider>
    );
};

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const useToast = () => useContext(ToastContext);

export { useToast, ToastProvider };
