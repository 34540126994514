import  { FC, useEffect } from "react";
import styles from "./toast.module.scss";

const VARIANTS = {
    Info: {
        base: "toast--info",
        name: "Info",
    },

    Error: {
        base: "toast--error",

        name: "Error",
    },

    Warning: {
        base: "toast--warning",
        name: "Warning",
    },

    Success: {
        base: "toast--success",
        name: "Success",
    },
};

type ToastMessageProps = {
    id: string;
    header: string;
    message: string;
    lifetime: number;
    onRemove: any;
    type: string;
    icon: string;
};

const ToastMessage: FC<ToastMessageProps> = ({
    id,
    header,
    message,
    lifetime,
    onRemove,
    type,
}) => {
    const Var = type
        ? VARIANTS[type as keyof typeof VARIANTS]
        : {
              base: "",
              name: header,
          };

    useEffect(() => {
        if (lifetime && onRemove) {
            setTimeout(() => {
                onRemove(id);
            }, lifetime);
        }
    }, [lifetime]);

    return (
        <div className={`${styles["toast"]} ${styles[Var.base]}`}>
            <div className={`${styles["toast__inner"]}`}>
                <div className={`${styles["toast__message-container"]}`}>
                    <h4
                        data-cy="toast-title"
                        className={`${styles["toast__heading"]}`}
                    >
                        {header ? header : Var.name}
                    </h4>

                    {message && (
                        <p className={`${styles["toast__message"]}`}>
                            {message}
                        </p>
                    )}
                </div>
                <div
                    onClick={() => onRemove && onRemove(id)}
                    className={`${styles["toast__close"]}`}
                />
            </div>
        </div>
    );
};

export { ToastMessage };
