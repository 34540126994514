import React from "react";

const CustomersIcon = (props) => (
    <svg
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="M7.20072 9.41177C8.47389 9.41177 9.69491 8.91597 10.5952 8.03344C11.4954 7.15092 12.0012 5.95396 12.0012 4.70588C12.0012 3.4578 11.4954 2.26084 10.5952 1.37832C9.69491 0.495797 8.47389 0 7.20072 0C5.92755 0 4.70653 0.495797 3.80627 1.37832C2.906 2.26084 2.40024 3.4578 2.40024 4.70588C2.40024 5.95396 2.906 7.15092 3.80627 8.03344C4.70653 8.91597 5.92755 9.41177 7.20072 9.41177ZM18.0018 9.41177C18.9567 9.41177 19.8724 9.03992 20.5476 8.37803C21.2228 7.71613 21.6022 6.81841 21.6022 5.88235C21.6022 4.94629 21.2228 4.04858 20.5476 3.38668C19.8724 2.72479 18.9567 2.35294 18.0018 2.35294C17.0469 2.35294 16.1312 2.72479 15.456 3.38668C14.7808 4.04858 14.4014 4.94629 14.4014 5.88235C14.4014 6.81841 14.7808 7.71613 15.456 8.37803C16.1312 9.03992 17.0469 9.41177 18.0018 9.41177ZM3.0003 11.7647C1.34413 11.7647 0 13.0824 0 14.7059C0 14.7059 0 20 7.20072 20C12.9085 20 14.0918 16.6729 14.3366 15.2941C14.4014 14.9341 14.4014 14.7059 14.4014 14.7059C14.4014 13.0824 13.0573 11.7647 11.4011 11.7647H3.0003ZM16.7921 15.5294C16.7644 15.956 16.6921 16.3786 16.5761 16.7906C16.4248 17.3153 16.1752 17.9435 15.7552 18.5906C16.4917 18.7523 17.2447 18.8305 17.9994 18.8235C24 18.8235 24 14.7059 24 14.7059C24 13.0824 22.6559 11.7647 20.9997 11.7647H15.8896C16.4656 12.6071 16.7993 13.6165 16.7993 14.7059V15.2941L16.7921 15.5294Z"
            fill="#000"
        />
    </svg>
);

export default CustomersIcon;
