import { FC, SVGProps } from "react";

const MoznLogo: FC<SVGProps<SVGSVGElement>> = () => (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Group">
            <path
                id="Vector"
                d="M3.26733 0.932461C2.13733 0.286747 0.738281 1.09389 0.738281 2.38532V17.5609C0.738281 18.8524 2.13733 19.6595 3.26733 19.0138L16.452 11.4266C17.582 10.7809 17.582 9.16665 16.452 8.52094L3.26733 0.932461Z"
                fill="#00FFA3"
            />
            <path
                id="Vector_2"
                d="M14.7314 0.93246C15.8614 0.286747 17.2604 1.09389 17.2604 2.38532V17.6147C17.2604 18.9062 15.8614 19.7133 14.7314 19.0676L1.54672 11.4791C0.416719 10.8334 0.416719 9.21912 1.54672 8.5734L14.7314 0.93246Z"
                fill="url(#paint0_linear_2920_5310)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_2920_5310"
                x1="17.2537"
                y1="-0.508287"
                x2="8.15721"
                y2="15.2471"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F106F5" stopOpacity="0.97" />
                <stop offset="1" stopColor="#032BFF" stopOpacity="0.4" />
            </linearGradient>
        </defs>
    </svg>
);

export { MoznLogo };
