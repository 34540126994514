import React from "react";

const SettingsIcon = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}>
        <path
            d="m18.713 10.827 2.487-1.96a10.107 10.107 0 0 0-1.982-3.525l-2.897 1.23L14.342 5.4l-.41-3.192a9.538 9.538 0 0 0-3.964 0L9.558 5.4 7.579 6.573 4.682 5.342A10.108 10.108 0 0 0 2.7 8.866l2.487 1.961v2.346L2.7 15.133c.42 1.302 1.094 2.502 1.982 3.525l2.897-1.23L9.558 18.6l.41 3.192a9.537 9.537 0 0 0 3.964 0l.41-3.192 1.979-1.173 2.897 1.231a10.106 10.106 0 0 0 1.982-3.524l-2.487-1.961v-2.346Zm-6.763 4.355a3.042 3.042 0 0 1-1.722-.536 3.165 3.165 0 0 1-1.141-1.428 3.26 3.26 0 0 1-.176-1.839 3.21 3.21 0 0 1 .848-1.629 3.074 3.074 0 0 1 1.586-.87 3.024 3.024 0 0 1 1.791.18c.566.241 1.05.65 1.39 1.172a3.243 3.243 0 0 1-.386 4.017 3.062 3.062 0 0 1-2.19.933Z"
            fill="#000"
        />
    </svg>
);

export default SettingsIcon;
