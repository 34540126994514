import { IEventDetailsResponse } from "../interfaces/events";
import { IInfo } from "../interfaces/shared";

export const INDIVIDUAL = "individual";
export const FRAUD_TITLE = "Fraud";
export const SELECT_OPTION_LABEL = "Select...";
export const ACCESS_TOKEN_KEY = "access_token";
export const USER = "user";
export const NODE_ENV = process.env.NODE_ENV as string;
export const IS_DEVELOPMENT = ["development", "localdev"].includes(
    process.env.NODE_ENV as string
);
export const KEYCLOAK_URL = process.env.VITE_APP_KEYCLOAK_URL as string;
export const KEYCLOAK_CLIENT_ID = process.env
    .VITE_APP_KEYCLOAK_CLIENT_ID as string;
export const KEYCLOAK_REALM = process.env.VITE_APP_KEYCLOAK_REALM as string;
export const FLIPT_URL = process.env.VITE_APP_FLIPT_URL as string;
export const FLIPT_NAMESPACE = process.env.VITE_APP_FLIPT_NAMESPACE as string;
export const IS_ON_PREMISE = process.env.VITE_APP_ON_PREMISE === "true";

export const DASHBOARD = "Dashboard";
export const EVENTS = "Events";
export const CUSTOMERS = "Customers";
export const DEVICES = "Devices";
export const LISTS = "Lists";
export const SETTINGS = "Settings";

export const LOCALSTORAGE_USER_INFO = "USER_INFO";

export const generalInfo: (data: IEventDetailsResponse) => IInfo[] = (data) => [
    {
        title: "Customer ID",
        contant: {
            text: data.customer_id,
            hyperlink: true,
        },
    },
    {
        title: "Event ID",
        contant: {
            text: data.id,
        },
    },
    {
        title: "Score",
        contant: {
            score: data.score,
            hint: "Event's total score is the sum of all Rule Scores found below.",
        },
    },
    {
        title: "State",
        contant: {
            state: data.state,
            hint: "Event's state is auto generated based on pre-defined auto-decisioning thresholds. Thresholds can be controlled in settings.",
        },
    },
    {
        title: "Date",
        contant: {
            date: data.timestamp,
        },
    },
    {
        title: "Time",
        contant: {
            time: data.timestamp,
        },
    },
    {
        title: "Event type",
        contant: {
            text: data.event_type,
        },
    },
    {
        title: "GPS Location",
        contant: {
            text: data.location,
        },
    },
    {
        title: "IP Location",
        contant: {
            text: data.ip_location,
        },
    },
    {
        title: "IP Address",
        contant: {
            text: data.ip_address,
        },
    },
];
export const devicesInfo: (data: any, hyperlink: boolean) => IInfo[] = (
    data,
    hyperlink
) => [
    {
        title: "Device Type",
        contant: {
            text: data.type,
        },
    },
    {
        title: "Device ID",
        contant: {
            text: data.reference || data.id,
            hyperlink: hyperlink,
        },
        blacklisted: data.blacklisted,
        blacklisted_at: data.blacklisted_at,
    },
    {
        title: "Browser",
        contant: {
            text: data.browser,
        },
    },
    {
        title: "Operating System",
        contant: {
            text: data.os,
        },
    },
    {
        title: "Other Connected Customers",
        contant: {
            dropDown: {
                listTitle: "Associated Customers",
                listItems: data.connected_customers,
            },
            hint: "Focal runs network analysis that connects accounts that have been accessed using the same devices.",
        },
    },
];

export const THRESHOLD_SCORES = {
    low: 30,
    mid: 70,
};

export const CALENDAR_TYPES = {
    GREGORIAN: "gregorian",
    HIJRI: "hijri",
};

export const eventTableHeaders = [
    {
        key: "reference",
        label: "Event ID",
    },
    {
        key: "customer_reference",
        label: "Customer ID",
    },
    {
        key: "score",
        label: "Score",
    },
    {
        key: "state",
        label: "State",
    },
    {
        key: "date",
        label: "Date",
    },
    {
        key: "event_type",
        label: "Event type",
    },
];

export const deviceTableHeaders = [
    {
        key: "reference",
        label: "Device ID",
    },
    {
        key: "type",
        label: "Device Type",
    },
    {
        key: "browser",
        label: "Browser",
    },
    {
        key: "number_of_connected_customers",
        label: "Count of Connected Customers",
    },
    {
        key: "os",
        label: "Operating System",
    },
    {
        key: "associated_events",
        label: "Associated Events",
    },
];

export const customerTableHeaders = [
    {
        key: "reference",
        label: "Customer ID",
    },
    {
        key: "username",
        label: "User Name",
    },
    {
        key: "number_of_rejected_events",
        label: "Reject Events",
    },
    {
        key: "number_of_reviewed_events",
        label: "Review Events",
    },
    {
        key: "number_of_approved_events",
        label: "Approve Events",
    },
];

export const eventsTableHeaders = [
    {
        key: "pin",
        label: "",
    },
    {
        key: "reference",
        label: "Event ID",
    },
    {
        key: "score",
        label: "Score",
    },
    {
        key: "state",
        label: "State",
    },
    {
        key: "date",
        label: "Date",
    },
    {
        key: "event_type",
        label: "Event type",
    },
];

export const devices_eventsTableHeaders = [
    {
        key: "pin",
        label: "",
    },
    {
        key: "customer_reference",
        label: "Customer ID",
    },
    {
        key: "reference",
        label: "Event ID",
    },
    {
        key: "score",
        label: "Score",
    },
    {
        key: "state",
        label: "State",
    },
    {
        key: "date",
        label: "Date",
    },
    {
        key: "event_type",
        label: "Event type",
    },
];

export const devicesTableHeaders = [
    {
        key: "type",
        label: "Device Type",
    },
    {
        key: "reference",
        label: "Device ID",
    },
];

export const GROUP_BY = {
    DAY: "day",
    WEEK: "week",
    MONTH: "month",
};

export const ROLES = {
    ADMIN: "Admin",
} as const;

export const STATUS_COLOR = {
    REVIEW: "#EBB228",
    APPROVE: "#52C8AC",
    REJECT: "#F23C3C",
} as const;

export const DATE_FORMAT = "yyyy-MM-dd";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = "dd MMM yyyy, hh:mm:ss a";

export const FLIPT_FLAGS = {
    threshold: "threshold",
    "allow-deny": "allow-deny",
    webhooks: "webhooks",
    "download-events": "download-events",
} as const;
