import React from "react";

const DeviceIcon = (props) => {
    return (
        <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path
                d="M9 12H3V3C3 2.46957 3.21071 1.96086 3.58579 1.58579C3.96086 1.21071 4.46957 1 5 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V5M1 15H13"
                stroke="#7370FF"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17 14H17.01M13 6.2C13 5.537 13.597 5 14.333 5H19.667C20.403 5 21 5.537 21 6.2V15.8C21 16.463 20.403 17 19.667 17H14.333C13.597 17 13 16.463 13 15.8V6.2Z"
                stroke="#7370FF"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default DeviceIcon;
