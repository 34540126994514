import { FC, useState } from "react";
import { useClickAway } from "@/hooks/useClickAway";
import "./AppsNavigator.scss";
import { CloseIcon } from "@/components/icons/close";
import { MoznLogo } from "@/components/icons/mozn-logo";
import { AppsIcon } from "@/components/icons/apps";

const AppsNavigator: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const ref = useClickAway<HTMLDivElement>(() => {
        onClose();
    });

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const onClose = () => {
        setIsOpen(false);
    };

    const onNavigateToFocal = () => {
        onClose();
        window.open(
            `${process.env.VITE_APP_FOCAL_URL}/alerts/device-risk`,
            "_self"
        );
    };

    return (
        <div ref={ref}>
            <div onClick={handleOpen}>
                <AppsIcon />
            </div>
            {isOpen && (
                <div className="switch-container">
                    <p className="header">
                        <span>Switch To</span>
                        <CloseIcon fill="#C0C0CB" onClick={onClose} />
                    </p>

                    <p className="option grayout">
                        <MoznLogo />
                        <span>Focal Fraud</span>
                    </p>

                    <p className="separator" />

                    <p className="option" onClick={onNavigateToFocal}>
                        <MoznLogo />
                        <span>Focal AML</span>
                    </p>
                </div>
            )}
        </div>
    );
};

export { AppsNavigator };
