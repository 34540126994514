import { useRef, useEffect, useLayoutEffect, MutableRefObject } from "react";
import { on, off } from "@/helpers/events";

type Callback = (event: MouseEvent | TouchEvent) => void;

export const useClickAway = <T>(
  cb: Callback
): MutableRefObject<T | null> => {
  const ref = useRef<T>(null);
  const refCb = useRef<Callback>(cb);

  useLayoutEffect(() => {
    refCb.current = cb;
  });

  useEffect(() => {
    const handler = (e: MouseEvent | TouchEvent) => {
      const element: any = ref.current;
      if (element && !element.contains(e.target as Node)) {
        refCb.current(e);
      }
    };

    on(document, "mousedown", handler);
    on(document, "touchstart", handler);
    return () => {
      off(document, "mousedown", handler);
      off(document, "touchstart", handler);
    };
  }, []);

  return ref;
};
