import { createBrowserHistory } from "history";
import { EVENTS_ROUTE } from "../constants/routes";

const history = createBrowserHistory({
    /* pass a configuration object here if needed */
    basename: "/",
});

history.listen((location, action) => {
    // if user back to screen page, set the flag usePrevForm to true
    if (action === "POP" && location.pathname === EVENTS_ROUTE) {
        history.replace(location.pathname, {
            ...location.state,
            usePrevForm: true,
        });
    }
});

export default history;
